import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { SurveyType } from '@kvika/api-types';
import { getTranslation, Lang } from '../utils/languages';
import { SelectedTabType } from '../types/Types';
import { setIsLegalEntity, setSurveyType } from '../utils/authenticationStorage';
import { selectLang, updateLang } from '../store/lang';
import Login from '../components/Login/Login';

const Home: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [surveyTabType, setSurveyTabType] = useState(SelectedTabType.INDIVIDUAL_TYPE);

  const router = useRouter();
  const lang = useSelector(selectLang);

  useEffect(() => {
    setSurveyType(SurveyType.Aml);
    setIsLegalEntity(false);
  }, []);
  useEffect(() => {
    const { surveyType, lang } = router.query;

    if (lang === 'en') {
      dispatch(updateLang(Lang.EN));
    } else {
      dispatch(updateLang(Lang.IS));
    }

    if (surveyType === 'legal_entity') {
      setSurveyTabType(SelectedTabType.LEGAL_ENTITY_TYPE);
    } else if (surveyType === 'individual') {
      setSurveyTabType(SelectedTabType.INDIVIDUAL_TYPE);
    } else {
      setSurveyTabType(SelectedTabType.INDIVIDUAL_TYPE);
    }
  }, [router.query]);

  const amlTitle_string = getTranslation(lang, 'Áreiðanleikakönnun', 'Kvika', 'Kvika');

  return <Login initialTabType={surveyTabType} isLegalBinder={false} title={amlTitle_string} />;
};

export default Home;
