import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { AuthLoginInitResponseSchema, DisruptionTypeEnum, SurveyType } from '@kvika/api-types';
import { Login } from '@kvika/slices';
import Button from '@kvika/button';
import { decodeBase64String, sanitisePhoneNumber } from '@kvika/string-utils';
import Modal, { ModalHeader, ModalSize } from '@kvika/modal';
import { useMediaQuery } from '@kvika/hooks';
import { MediaQuery } from '@kvika/theme';
import { JustifyContentType } from '@kvika/layout';
import { ApiError } from '@kvika/api-client';
import { AxiosResponseHeaders } from 'axios';
import { captureException, setUser } from '@sentry/nextjs';
import { getTranslation } from '../../utils/languages';
import { loginPending, loginSuccessful, loginFailed, setChildrenInfo } from '../../store/auth';
import {
  ServiceStatusMode,
  ErrorType,
  SelectedTabType,
  ErrorCodes,
  LoginMethodsEnum,
  LoginStatusProps,
} from '../../types/Types';
import {
  getIsLegalEntity,
  setExternalId,
  setIsLegalEntity,
  setSessionPayload,
  setSurveyType,
  setToken,
} from '../../utils/authenticationStorage';
import { clearServiceStatus, displayError, updateError, updateServiceStatus } from '../../store/error';
import { selectLang } from '../../store/lang';
import { SegmentTrackingId, trackEvent } from '../../utils/analytics';
import LayoutWrapper from '../LayoutWrapper';
import { getKvikaApiClient } from '../../utils/surveyUtils';
import { getErrorEvent, parseApiError } from '../../utils/utils';
import { StyledModalBody } from './LoginStyles';

type LoginProps = {
  initialTabType?: SelectedTabType;
  isLegalBinder: boolean;
  legalBinderPhoneNumber?: string;
  title: string;
  surveyType?: SurveyType;
  showTabs?: boolean;
};

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const StyledTitle = styled.h1`
  text-align: left;
  font-size: 70px;
  line-height: 68px;
  font-weight: 400;
  line-height: 40px;
  color: #333333;
  margin: 0 0 16px 0;
  word-wrap: break-word;

  @media (max-width: 480px) {
    font-size: 32px;
  }
`;

const StyledNavigation = styled.div`
  max-width: 448px;
`;

const LoginWrapper: React.FunctionComponent<LoginProps> = ({
  initialTabType,
  isLegalBinder = false,
  legalBinderPhoneNumber = '',
  title,
  surveyType = SurveyType.Aml,
  showTabs = true,
}) => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState(legalBinderPhoneNumber);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const lang = useSelector(selectLang);
  const isMobile = useMediaQuery(`(max-width: ${MediaQuery.Mobile})`);
  const [selectedLoginMethod, setSelectedLoginMethod] = useState<LoginMethodsEnum>(LoginMethodsEnum.ELECTRONIC_ID);
  const [ssn, setSsn] = useState<string>('');
  const router = useRouter();
  const [verificationCode, setVerificationCode] = React.useState('');
  const isLoggingInWithPhoneNumber = selectedLoginMethod === LoginMethodsEnum.ELECTRONIC_ID;

  const onResponseHeaders = (headers: AxiosResponseHeaders) => {
    const disruptionType = headers['x-disruption-type'] as DisruptionTypeEnum;
    const disruptionMessage = headers['x-disruption-message'] as string;

    if (disruptionType === DisruptionTypeEnum.EXTERNAL) {
      const newServiceStatus: ServiceStatusMode = {
        type: disruptionType,
        message: disruptionMessage,
        header: ErrorType.ERROR_SYSTEM_DISRUPTION,
      };
      dispatch(updateServiceStatus(newServiceStatus));
    } else if (!disruptionType) {
      dispatch(clearServiceStatus());
    }
  };

  const apiClient = getKvikaApiClient(onResponseHeaders);

  useEffect(() => {
    setSurveyType(surveyType);
    setIsLegalEntity(initialTabType === SelectedTabType.LEGAL_ENTITY_TYPE);
  }, [initialTabType, surveyType]);

  useEffect(() => {
    setPhoneNumber(legalBinderPhoneNumber);
  }, [legalBinderPhoneNumber]);

  const loadChildren = async (loginRequestToken: string) => {
    getKvikaApiClient(onResponseHeaders, loginRequestToken)
      .getChildren(surveyType)
      .then((response) => {
        if (response.length > 0) {
          dispatch(setChildrenInfo(response));
          router.push('/children');
        } else {
          routeToSurvey();
        }
      })
      .catch((error: ApiError) => {
        dispatch(updateError(parseApiError(error)));
      });
  };

  const titleString = getTranslation(lang, 'Skráðu þig inn', 'Log in', 'Zaloguj sie');
  const subtitleString = getTranslation(
    lang,
    'Sláðu inn símanúmer hér fyrir neðan til að auðkenna þig með rafrænum skilríkjum.',
    'Enter your phone number below to identify yourself with an electronic ID.',
    'Wpisz poniżej swój numer telefonu, aby zidentyfikować się za pomocą elektronicznego identyfikatora.'
  );
  const phoneNumberString = getTranslation(lang, 'Símanúmer', 'Phone number', 'Numer telefonu');
  const ssnString = getTranslation(lang, 'Kennitala', 'Social security number', '');
  const forwardString = getTranslation(lang, 'Innskráning', 'Proceed', 'Naprzód');
  const notValidMobilePhoneNumberMsg = getTranslation(lang, 'Ekki gilt farsímanúmer', 'Not a valid mobile number', '');
  const electronicIdFailedString = getTranslation(lang, 'Auðkenning tókst ekki', 'Authentication unsuccessful', '');
  const electronicIdInPhoneText = getTranslation(lang, 'Rafræn skilríki í síma', 'Electronic ID in phone', '');
  const electronicIdRequestSentText = getTranslation(
    lang,
    'Auðkenningarbeiðni hefur verið send í símanúmerið þitt {0}. Vinsamlega staðfestu innskráningu',
    'Authentication request has been sent to you phone number {0}. Please confirm your login',
    ''
  );
  const numberDoesNotHaveElectronicIdText = getTranslation(
    lang,
    'Þetta símanúmer er ekki með rafræn skilríki',
    'This phone number does not have electronic ID',
    ''
  );
  const ssnModalTitle = getTranslation(lang, 'Auðkennis appið', 'Auðkenni app', '');
  const ssnModalBodyText = getTranslation(
    lang,
    'Opnaðu auðkennisappið til að klára innskráningu.\nÖryggisnúmer: ',
    'Open the Auðkenni app to complete the login.\nSecurity number: ',
    ''
  );

  const invalidSsnMsg = getTranslation(lang, 'Vitlaus kennitala', 'invalid Ssn', '');

  const tabs = [
    getTranslation(lang, 'Einstaklingur', 'Individual', ''),
    getTranslation(lang, 'Lögaðili', 'Legal entity', ''),
  ];
  const loginMethods = [
    getTranslation(lang, 'Rafræn skilríki', 'Electronic ID', ''),
    getTranslation(lang, 'Auðkennisappið', 'Audkenni app', ''),
  ];

  const routeToSurvey = () => {
    router.push({
      pathname: '/survey',
      query: {
        isChild: false,
        isCompany: false,
        hasCompanies: false,
        hasChildren: false,
      },
    });
  };

  const onPress = () => {
    const phoneNumberSanitised = sanitisePhoneNumber(phoneNumber);
    // Update the input field
    setPhoneNumber(phoneNumberSanitised);
    const loginType = getIsLegalEntity() ? 'legal_entity' : 'individual';
    // Trigger pending login.
    dispatch(loginPending());
    trackEvent({
      event: SegmentTrackingId.LoginStarted,
      properties: { identifier: isLoggingInWithPhoneNumber ? phoneNumber : ssn, surveyType: loginType },
    });
    // Start the login dance.
    setShowModal(true);
    setIsLoading(true);
    apiClient
      .postStartLogin({ identifier: isLoggingInWithPhoneNumber ? phoneNumberSanitised : ssn })
      .then((response) => {
        setToken(response.loginRequestToken);
        onStartLoginSuccess(response);
      })
      .catch((error) => {
        setShowModal(false);
        setIsLoading(false);

        const disruptionType = error.response.headers['x-disruption-type'];
        if (disruptionType === DisruptionTypeEnum.MAINTENANCE) {
          const disruptionMessage = decodeBase64String(error.response.headers['x-disruption-message']);
          dispatch(
            displayError({
              showModal: true,
              modalErrorText: disruptionMessage,
              modalErrorHeaderText: getTranslation(lang, 'Kerfisuppfærsla í gangi', 'Ongoing system maintenance', ''),
            })
          );
          dispatch(loginFailed());
        } else {
          let errorMessage = electronicIdFailedString;
          const modalErrorHeaderText = electronicIdInPhoneText;
          if (error?.response?.data.code === ErrorCodes.PhoneNumberHasNoElectronicId) {
            errorMessage = numberDoesNotHaveElectronicIdText;
          }
          trackEvent({
            event: SegmentTrackingId.LoginFailed,
            properties: { phoneNumber: phoneNumberSanitised, loginType },
          });
          dispatch(displayError({ showModal: true, modalErrorText: errorMessage, modalErrorHeaderText }));
          dispatch(loginFailed());
        }
        captureException(getErrorEvent(error));
      });
  };

  const onStartLoginSuccess = (response: AuthLoginInitResponseSchema) => {
    // If we get a verification code in the response that means we are using the auðkenni app
    response.verificationCode && setVerificationCode(response.verificationCode);

    // Wait 6 seconds until we check the login status since it always takes a few seconds to finish signing with EID or Auðkenni app
    setTimeout(() => {
      const currentDate = new Date();
      // We create this to stop polling 120 seconds from now
      const finalPollingTime = new Date(currentDate.getTime() + 120000);
      checkLoginStatus({
        loginRequestToken: response.loginRequestToken,
        firstTime: 0,
        secondTime: 1000,
        finalPollingTime,
      });
    }, 6000);
  };

  const checkLoginStatus = ({
    loginRequestToken,
    firstTime = 0,
    secondTime = 1000,
    finalPollingTime = new Date(),
  }: LoginStatusProps) => {
    loginRequestToken &&
      getKvikaApiClient(onResponseHeaders, loginRequestToken)
        .getLoginStatus(isLoggingInWithPhoneNumber ? phoneNumber : ssn)
        .then((response) => {
          const sessionPayload = {
            ssn: response.ssn,
            name: response.fullName,
            isLivingAbroad: response.address.isLivingAbroad,
            externalId: response.externalId,
          };
          setSessionPayload(sessionPayload);
          setExternalId(response.externalId);
          setUser({ id: response.externalId });
          dispatch(loginSuccessful(sessionPayload));
          if (isLegalBinder) {
            router.push({
              pathname: '/thank-you',
              query: { ack: true, isCompany: false, isChild: false, hasChildren: false },
            });
          } else if (!getIsLegalEntity()) {
            loadChildren(loginRequestToken);
          } else if (getIsLegalEntity()) {
            router.push('/companies');
          }
        })
        .catch((loginError: ApiError) => {
          // 412 means the process is still on going so we try again
          if (loginError.response?.status === 412) {
            onLoginRetry(loginRequestToken, firstTime, secondTime, finalPollingTime);
          } else {
            onLoginError(loginError);
          }
        });

    const onLoginRetry = (loginRequestToken: string, firstTime: number, secondTime: number, finalPollingTime: Date) => {
      // Add this check so that we are not checking the login status infinitaly, stop if 120 seconds have passed since the first request
      if (finalPollingTime > new Date()) {
        const timeToWait = firstTime + secondTime;
        setTimeout(() => {
          // We do this to poll the api incrementally using fibonacci
          checkLoginStatus({
            loginRequestToken,
            firstTime: secondTime,
            secondTime: timeToWait,
            finalPollingTime,
          });
        }, timeToWait);
      } else {
        setShowModal(false);
        setIsLoading(false);
      }
    };
  };

  const onLoginError = (error: ApiError) => {
    const disruptionType = error.response?.headers['x-disruption-type'];
    if (disruptionType === DisruptionTypeEnum.MAINTENANCE) {
      const disruptionMessage = decodeBase64String(error.response?.headers['x-disruption-message']);
      dispatch(
        displayError({
          showModal: true,
          modalErrorText: disruptionMessage,
          modalErrorHeaderText: getTranslation(lang, 'Kerfisuppfærsla í gangi', 'Ongoing system maintenance', ''),
        })
      );
      dispatch(loginFailed());
    } else {
      let errorMessage = electronicIdFailedString;
      const modalErrorHeaderText = electronicIdInPhoneText;
      if (error?.response?.data.code === ErrorCodes.PhoneNumberHasNoElectronicId) {
        errorMessage = numberDoesNotHaveElectronicIdText;
      }
      trackEvent({
        event: SegmentTrackingId.LoginFailed,
        properties: { phoneNumber, ssn },
      });
      dispatch(displayError({ showModal: true, modalErrorText: errorMessage, modalErrorHeaderText }));
      dispatch(loginFailed());
    }
    captureException(getErrorEvent(parseApiError(error)));
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isDisabled) {
      onPress();
    }
  };

  return (
    <LayoutWrapper
      backgroundImageUrl="/mynd_vindmyllur.png"
      justifyContent={JustifyContentType.Center}
      alt={getTranslation(lang, 'Vindmyllur og landslag', 'Windmills and landscape', '')}
    >
      <>
        {showModal && isLoading && (
          <Modal
            closeOnClickOutside={false}
            autoFocus={false}
            size={ModalSize.SMALL}
            isVisible
            onClose={() => {
              setShowModal(false);
            }}
          >
            <ModalHeader showCloseButton={false}>
              {isLoggingInWithPhoneNumber ? electronicIdInPhoneText : ssnModalTitle}
            </ModalHeader>
            <StyledModalBody isLoginWithElectronicID={isLoggingInWithPhoneNumber}>
              {isLoggingInWithPhoneNumber ? (
                electronicIdRequestSentText.replace('{0}', phoneNumber)
              ) : (
                <div>
                  {ssnModalBodyText} <strong>{verificationCode}</strong>
                </div>
              )}
            </StyledModalBody>
          </Modal>
        )}
        <StyledTitle>{title}</StyledTitle>
        <Login
          key={initialTabType}
          translations={{
            loginTitle: titleString,
            loginSubtitle: subtitleString,
            label: isLoggingInWithPhoneNumber ? phoneNumberString : ssnString,
            invalidPhoneNumberMsg: notValidMobilePhoneNumberMsg,
            tabs,
            loginMethods,
            invalidSsnMsg,
          }}
          onValidationChange={(isValid) => {
            setIsDisabled(!isValid);
          }}
          onChange={(phoneNumber, ssn, selectedLoginMethod: LoginMethodsEnum, selectedTab) => {
            setPhoneNumber(phoneNumber);
            setSsn(ssn);
            setSelectedLoginMethod(selectedLoginMethod);
            if (showTabs) {
              setIsLegalEntity(selectedTab === 1);
            }
          }}
          defaultSelectedTab={initialTabType === SelectedTabType.LEGAL_ENTITY_TYPE ? 1 : 0}
          defaultSelectedLoginMethod={0}
          width="450px"
          onKeyPress={onKeyPress}
        />
        <StyledNavigation>
          <StyledButton width={isMobile ? '100%' : '40%'} disabled={isDisabled} loading={isLoading} onClick={onPress}>
            {forwardString}
          </StyledButton>
        </StyledNavigation>
      </>
    </LayoutWrapper>
  );
};

export default LoginWrapper;
